import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './components/Home/Home';
import MainHeader from './components/MainHeader/MainHeader';
import Footer from './components/Footer/Footer'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-datetime/css/react-datetime.css';
import 'font-awesome/css/font-awesome.min.css';
import TodayResult from './components/Results/TodayResult';
import OldResult from './components/Results/OldResult';
import Live from './components/Live/Live';
import News from './components/News/News';
import ContactUs from './components/ContactUs/ContactUs';
import Acts from './components/Acts/Acts';
import ClaimForm from './components/ClaimForm/ClaimForm';
import MizoramForm from './components/ClaimForm/MizoramForm';
import GoaForm from './components/ClaimForm/GoaForm';
import SiteMap from './components/SiteMap/SiteMap';
import NotificationHandler from './components/Notification/NotificationHandler';
import ScrollToTop from './components/UI/ScrollToTop';
import Notification from './components/Notification/Notification';
import { AppProvider } from './components/Context/AppContext';
function App() {
  return (
    <AppProvider>
      <NotificationHandler />
      <ScrollToTop/>
      <MainHeader />
      <Routes>
        <Route exact path='/' element={<Home/>} />
        <Route path='/today-result' element={<TodayResult/>} />
        <Route path='/old-result' element={<OldResult/>} />
         <Route path='/live' element={<Live/>} />
        {/* <Route path='/verify-ticket' element={<VerifyTicket/>} /> */}
        <Route path='/act-rules' element={<Acts/>}/>
        <Route path='/news-updates' element={<News/>}/>
        <Route path='/contact' element={<ContactUs/>}/>
        <Route path='/claims' element={<ClaimForm/>}/>
        <Route path='/mizoram-form' element={<MizoramForm/>}/>
        <Route path='/goa-form' element={<GoaForm/>}/>
        <Route path='/sitemap' element={<SiteMap/>}/>
        <Route path='/notification' element={<Notification/>}/>
      </Routes>
      <Footer/>
      </AppProvider>
  );
}

export default App; 
